import Layout from '../components/Layout'
import React from 'react'
import SectionActualities from '../components/SectionActualities'
import Seo from '../components/Seo'
import fr from '../locales/fr'
import { graphql } from 'gatsby'

interface ActualitiesPageProps {
  data: GatsbyTypes.ActualitiesPageQuery
}

const ActualitiesPage: React.FunctionComponent<ActualitiesPageProps> = ({ data }) => (
  <Layout>
    <Seo title={fr.actualities} description={fr.actualityMetaDesc} />
    <SectionActualities actualities={data.actualities.nodes} />
  </Layout>
)

export default ActualitiesPage

export const pageQuery = graphql`
  query ActualitiesPage {
    actualities: allWpPost(
      sort: { fields: [date], order: DESC }
      filter: { categories: { nodes: { elemMatch: { slug: { eq: "actualite" } } } } }
    ) {
      nodes {
        ...Post
      }
    }
  }
`
